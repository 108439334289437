import Swiper from "swiper";
import "swiper/css";

export default function mediaGallery() {
	const carousel = this.$refs.carousel;
	const homeHeader = document.getElementById("homeHeader");
	const homeFooter = document.getElementById("homeFooter");
	const swiper = new Swiper(carousel, {
		slidesPerView: "auto",
		spaceBetween: 0,
		duration: 300,
		grabCursor: true,
	});
	return {
		activeItem: "01",
		init() {
			setTimeout(() => {
				this.setHeight();
			}, 300);

			window.addEventListener("resize", () => {
				this.setHeight();
			});

			swiper.on("slideChange", () => {
				const adjustedIndex = swiper.realIndex + 1;
				this.activeItem = adjustedIndex.toString().padStart(2, "0");
			});
		},
		setHeight() {
			const headerHeight = homeHeader.offsetHeight;
			const footerHeight = homeFooter.offsetHeight;
			const totalHeight = headerHeight + footerHeight + 40;
			console.log(headerHeight, footerHeight, totalHeight);
			carousel.style.height = `calc(100vh - ${totalHeight}px)`;
		},
		next() {
			swiper.slideNext();
		},
		prev() {
			swiper.slidePrev();
		},
	};
}
